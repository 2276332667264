<template>
    <HomeContentLayout>
        <Layout>
            <Sider  hide-trigger :collapsed-width="64"  class="siderCls">
                <el-tree :data="orgData" 
                         :props="defaultProps" 
                         @node-click="handleNodeClick" 
                         node-key="id"                       
                         :style="{height: scrollerHeight,overflow:'auto',display: 'flex',padding:15}"
                         highlight-current
                         :filter-node-method="filterNode"
                         ref="eltree"
                         default-expand-all
                         :expand-on-click-node="false"
                >
                    <template v-slot="{node,data}">                                     
                        <span v-if="data.id ==0 "  class="el-icon-office-building nodeRoot" >
                          <span class="nodeLabel">{{ node.label}} </span>
                        </span>                  
                        <span v-else class="el-icon-share" > 
                            <span class="nodeLabel">{{ node.label}} </span>
                        </span>
                    </template>
                </el-tree>
            </Sider>
            <Layout>
                <Content>
                    <div class="tree-container">
                        <el-button type="primary" size="small" plain icon="el-icon-circle-plus-outline" @click="addDept">新增部门</el-button>
                        <el-button type="primary" size="small" plain icon="el-icon-edit" @click="editDept">编辑部门</el-button>
                        <el-button type="primary" size="small" plain icon="el-icon-delete" @click="delDept">删除部门</el-button>
                        <el-tag effect='plain' style="margin-right:5px; margin-left:40px;">图形方向:</el-tag>
                        <el-radio-group v-model="orgval" size="small"  @change="toggleOrg">
                            <el-radio label="水平方向" border></el-radio>
                            <el-radio label="垂直方向" border></el-radio>
                        </el-radio-group>
                         
                    </div>
                    <div class="tree-container" id="tree" ref="treeContainer">
                        <TreeChart 
                        :json="treeData"
                        :class="{landscape: isVertical}"
                        :isDetail="isDetail"
                        />
                    </div>
                    <Modal v-model="addModal"  :closable="false"  scrollable :title="type==='create'?'新增部门':'修改部门'"  width="620">
                        <Form ref="addForm" :model="formCol" :label-width="120"  :rules="ruleValidate"  label-position="right" inline >
                            <span v-for="(item,index) in tabCol" :key="index" >
                                <FormItem :label="item.FLDNM" :prop="item.FLDNO" v-if="item.FHIDE==='N'">                                  
                                    <Input type="text" :disabled="item.DISABLED==='Y'?true:false" v-model="formCol[item.FLDNO]"  style="width:140px;" clearable/>
                                </FormItem>
                            </span>
                        </Form>                     
                        <div slot="footer">
                            <Button type="text"   @click="addModal=false">取消</Button>
                            <Button type="primary"   @click="modal_ok">确定</Button>
                        </div>
                    </Modal>
                    <BackTop :height=100 :bottom=400>
                        <div class="top">返回顶端</div>
                    </BackTop>
 
                </Content>
            </Layout>

        </Layout>
        
    </HomeContentLayout>
    
</template>

<script>
  import TreeChart from '@/components/TreeData'
  import { getTabColOrData } from '../../api/user'
  export default {
    name: 'hr_organize',
    components: {
      TreeChart
    },
    data() {
      return {
        treeData:{}, //图形树结构数据 
        addModal:false, //modal 
        formCol:{}, //表格单行记录 model
        orgData:[] , //树结构数据
        defaultProps: {  //el-tree 默认字段属性
            children: 'children',
            id:'id',
            icon:'icon',
            parentid:'',
            label: 'label'
        },
        ruleValidate:{}, //校验规则
        type: 'create',
        nodeLabel:'中山景荣电子有限公司',
        treeid :'0',
        parentid:'',
        isVertical: false, // 是否是竖方向,只给最外层的添加
        isDetail: true, // 是否是详情,不可编辑操作
        orgval:'水平方向',
        tabCol:[] ,//表列集合
        v_url:this.$store.state.queryUrl , //api请求路径
        v_urlcol:this.$store.state.baseUrl+'sysprivs/getFormColumn',                
        v_username:this.$cookies.get('v_username'), //api 请求用户
        v_password:this.$cookies.get('v_password') ,//api 请求密码
      }
    },
    created () {
      this.getData()  
      this.getTabCol() // []
      this.getFormCol() //{} 行记录
    },
    mounted () {
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','106','fty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });  
    },
    computed:{
            scrollerHeight: function() {
                return (window.innerHeight - 150) + 'px';
            },
            
        },
    methods: {
        //确定 保存
        modal_ok(){
            var vm=this;
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.formCol[item.FLDNO] ){
                    this.stfgBool =false 
                    this.$Message.error(item.FLDNM+'不能为空'); 
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
           // console.log(JSON.stringify(vm.formCol) )
            //发起保存请求   
            if (this.stfgBool) 
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(vm.formCol), p_table:'hr_frame'},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.addModal =false   
                        vm.getData()       
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
        } ,
        editDept(){
            this.addModal =true
            this.type ='edit'
            this.formCol ={}
            this.formCol.PARENTNO =this.parentid
            this.formCol.CNAME =this.nodeLabel  
            this.formCol.CODE=this.treeid
        },
        //新增
        addDept(){
            this.addModal =true
            this.type ='create'
            this.formCol ={}
            this.formCol.PARENTNO =this.treeid
            this.formCol.PCNAME =this.nodeLabel           
        },
        //获取表列头字段 ---数组，表单字段loop
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','106','fty','HEAD','thide','N','').then((res) => {
                vm.tabCol = res.data
            });
        },
        //获取表单字段 --对象 {} 行记录
        getFormCol(){
            var vm=this
            getTabColOrData(vm.v_urlcol,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','106','fty','HEAD','thide','N','').then((res) => {
                vm.formCol = res.data
            });
        },
        //切换方向  
        toggleOrg(){
            this.isVertical = !this.isVertical
        },
        //节点过滤
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        //节点点击
        handleNodeClick(data){
            this.nodeLabel=data.label;            
            this.treeid =data.id
            this.parentid=data.parentid
        },
        //删除部门
        delDept(){
            if (this.treeid ==='0'){
                this.$Message.error('根结点不能删除')
            }else{
                this.$Modal.confirm({
                        title: '删除确认',
                        content: '<p>你确定要删除此行记录吗</p>',
                        onOk: () => {
                            //发起删除请求
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                                data: {p_key:'code',p_value:this.treeid,p_table:'hr_frame'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {           
                                    vm.getData()       
                                }else{
                                    this.$Message.error(res.data.message)
                                }        
                            }) 
                        }
                    });
            }
            if (this.treeid ==='0'){
                this.$Message.error('根结点不能删除')
            }else{
                console.log(this.treeid)
            }
        },
        // 获取 树形控件数据
        getData() {  
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                data: {p_table:'V_HR_FRAME'}
            }).then(function(res){                  
                vm.orgData =res.data;  
                vm.treeData =res.data[0]
                //console.log(JSON.stringify(res.data[0])) 
                if(res.data.length > 0 ){
                    //'nextTick()' 下次dom更新时触发回调函数
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(vm.treeid)
                    })
                }               
            }).catch(function(err){
                if (err.response){
                    console.log(err.response.data+':'+err.response.status+err.response.headers)
                }
            })                              
        },
    }
  }
</script>
<style scoped>
 .siderCls{
     background:#fff;
     margin: 3px auto;
     width: 260px;
     min-width: 260px;
     max-width:260px;
     border-right: 2px solid #C0C4CC;
}
.tree-container{
    padding-left:5px; 
    width:100%;
    background:#fff;
    margin:2px auto ;
    margin-top:5px;
    overflow: auto;
}
  
.top{
        padding: 10px;
        background: rgba(20, 45, 146, .1);
        color: #fff;
        text-align: center;
        border-radius: 2px;
    }        
</style>
 
